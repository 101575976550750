import AutoComplete from 'autocomplete-js'

create_autocomplete = (element) ->
  mapping = Object.assign(
    AutoComplete.defaults.KeyboardMappings, {
      Enter:
        Conditions: [
          Is: 13,
          Not: false
        ]
        Callback: (eve) -> Turbolinks.visit "/productos?keyword=#{eve.target.value}"
    }
  )
  AutoComplete {
    Url: '/search.json'
    Delay: 800
    EmptyMessage: 'No se encontraron resultados.'
    KeyboardMappings: mapping
    _Select: (eve)->
      url = eve.dataset.autocompleteValue
      Turbolinks.visit(url)
  }, element

document.addEventListener  'turbolinks:load', () ->
  elements = document.getElementsByClassName 'autocomplete-search'
  create_autocomplete(el) for el in elements if elements.length
